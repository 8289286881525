<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    single-export="Dapat Ditinjau"
  />

</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_prestasiakm',
      headers: [
        [{
          label: 'Nama Mahasiswa',
          dataKey: 'data_master_dosen.full_name',
          order: 1,
          attributes: { rowspan: 2 }
        }, {
          label: 'Prestasi yang Ingin Dicapai',
          dataKey: 'prestasi_yang_dicapai',
          order: 2,
          attributes: { rowspan: 2 }
        }, {
          label: 'Waktu Pencapaian',
          dataKey: 'waktu_pencapaian',
          order: 3,
          attributes: { rowspan: 2 },
          dataAttributes: { class: 'text-center' },
        }, {
          label: 'Tingkat',
          attributes: { colspan: 3 }
        }],
        [{
          label: 'Internasional',
          name: 'internasional',
          dataKey: 'internasional',
          order: 4,
          dataAttributes: { class: 'text-center align-middle' },
          render(data) {
            return data ? '✓' : ''
          }
        }, {
          label: 'Nasional',
          name: 'nasional',
          dataKey: 'nasional',
          order: 5,
          dataAttributes: { class: 'text-center align-middle' },
          render(data) {
            return data ? '✓' : ''
          }
        }, {
          label: 'Lokal',
          name: 'lokal',
          dataKey: 'lokal',
          order: 6,
          dataAttributes: { class: 'text-center align-middle' },
          render(data) {
            return data ? '✓' : ''
          }
        }]
      ],
      form: {
        inputs: [{
          label: 'Nama Mahasiswa',
          name: 'data_master_dosen_id',
          async dataKey(data, input) {
            const response = await useJwt.http.get(`data_master_dosen/${data.data_master_dosen_id}?state=0`)
            if (response.data.data_master_dosens) {
              const { id: value, full_name: label } = response.data.data_master_dosens
              input.options = [{ label, value }]
              return value
            }
            return 0
          },
          type: 'select',
          options: [],
          placeholder: 'Cari nama mahasiswa',
          
          async fetchOptions(search, loading) {
            if (search.length >= 3) {
              loading(true)
              const response = await useJwt.http.get('data_master_dosen?state=0', { params: { full_name: search } })
              const fetchedOptions = response.data.data_master_dosens.map(dosen => ({ label: dosen.full_name, value: dosen.id }))
              loading(false)
              return fetchedOptions
            }
          },
          customSelectedValue(data) {
            return data.value
          },
        }, {
          label: 'Prestasi yang Ingin Dicapai',
          name: 'prestasi_yang_dicapai',
          dataKey: 'prestasi_yang_dicapai',
          type: 'text',
          rules: 'required'
        }, {
          label: 'Tahun Pencapaian',
          name: 'waktu_pencapaian',
          dataKey: 'waktu_pencapaian',
          type: 'date',
          
          flatPickrConfig: {
            dateFormat: 'Y',
            altFormat: 'Y',
            ariaDateFormat: 'Y',
          }
        }, {
          label: 'Internasional',
          name: 'internasional',
          dataKey(data) {
            return data.internasional ? '✓' : ''
          },
          type: 'select',
          options: ['✓'],
          customSelectedValue(data) {
            return data == '✓' ? 1 : 0
          },
          col: 4
        }, {
          label: 'Nasional',
          name: 'nasional',
          dataKey(data) {
            return data.nasional ? '✓' : ''
          },
          type: 'select',
          options: ['✓'],
          customSelectedValue(data) {
            return data == '✓' ? 1 : 0
          },
          col: 4
        }, {
          label: 'Lokal',
          name: 'lokal',
          dataKey(data) {
            return data.lokal ? '✓' : ''
          },
          type: 'select',
          options: ['✓'],
          customSelectedValue(data) {
            return data == '✓' ? 1 : 0
          },
          col: 4
        }],
        customPayload(data) {
          return {
            ...data,
            internasional: data.internasional ? 1 : 0,
            nasional: data.nasional ? 1 : 0,
            lokal: data.lokal ? 1 : 0,
          }
        }
      }
    }
  },
}
</script>